"use client"

import { useSession } from "next-auth/react"
import type { AwsRumConfig } from "aws-rum-web"
import { AwsRum } from "aws-rum-web"
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers"
import { createContext, useLayoutEffect, useState } from "react"
import { Session } from "@tc/ui-dfe/utils/types"

export const CloudWatchRumContext = createContext<AwsRum | null>(null)

export interface CloudWatchRumProviderProps extends AwsRumConfig {
  children: React.ReactNode
  applicationId: string
  applicationVersion: string
  identityPoolId: string
  userPoolId: string
  region: string
}

// TODO fix lint errors:
/*
packages/ui-rum/src/lib/hooks/CloudWatchRumProvider.tsx
  48:6  warning  React Hook useLayoutEffect has missing dependencies: 'applicationId', 'applicationVersion', 'config', 'idToken', 'identityPoolId', 'region', and 'userPoolId'. Either include them or remove the dependency array  react-hooks/exhaustive-deps
 */

export const CloudWatchRumProvider = (props: CloudWatchRumProviderProps) => {
  const {
    children,
    applicationId,
    applicationVersion,
    identityPoolId,
    userPoolId,
    region,
    ...config
  } = props
  const [client, setClient] = useState<AwsRum>()

  const session = useSession()
  const idToken = (session?.data as Session)?.token?.id_token

  useLayoutEffect(() => {
    if (idToken) {
      const credentials = fromCognitoIdentityPool({
        clientConfig: { region: region },
        identityPoolId: identityPoolId,
        logins: {
          [`cognito-idp.${region}.amazonaws.com/${userPoolId}`]: idToken,
        },
      })
      const rum = new AwsRum(applicationId, applicationVersion, region, config)
      rum.setAwsCredentials(credentials)
      setClient(rum)
    }
  }, [])
  if (!client) return null
  return (
    <CloudWatchRumContext.Provider value={client}>
      {props.children}
    </CloudWatchRumContext.Provider>
  )
}
