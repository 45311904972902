export enum SearchFieldId {
  CertificateType = "CertificateType",
  DestinationMarket = "DestinationMarket",
  Commodity = "Commodity",
  FieldId = "FieldId",
  CountryCode = "CountryCode",
  WineCategory = "WineCategory",
  DeAlcoholised = "DeAlcoholised",
  WineChemistryTest = "WineChemistryTest",
  Eligibility = "Eligibility",
  ProcessType = "ProcessType",
  BusinessArea = "BusinessArea",
  ImportCertificateType = "ImportCertificateType",
  Verifier = "verifiers",
  HsCode = "hs-codes",
  Package = "package-types",
  FreeSalePremise = "free-sale-premises",
  WineSpecialProcess = "wine-special-processes",
  WineGrapeVariety = "wine-grape-varieties",
  WineColor = "wine-colours",
  WineStyle = "wine-styles",
  WineIngredient = "eu-wine-ingredients",
  GeographicIndication = "wine-geographic-indications",
  UnLocode = "un-locode",
  Document = "document-types",
  CertificateDeliveryMethod = "certificate-delivery-methods",
  WSMPS = "wsmps",
  BottleSize = "bottle-sizes",
  EstarLaboratory = "estar-laboratories",
  Product = "products",
  ExportCertificate = "export-certificates",
  OrganicPremise = "organic-premises",
  // the name not yet finalized
  EstarWineTest = "estar-wine-test",
  Exporter = "exporters",
}

export const SearchFieldCollectionMappings: Record<SearchFieldId, string> = {
  [SearchFieldId.CertificateType]: "public:certificate-types",
  [SearchFieldId.DestinationMarket]: "public:destination-markets",
  [SearchFieldId.Commodity]: "public:commodity-types",
  [SearchFieldId.FieldId]: "ec:product-fields",
  [SearchFieldId.CountryCode]: "public:countries",
  [SearchFieldId.WineCategory]: "ec:wine-eu-categories",
  [SearchFieldId.WineChemistryTest]: "ec:wine-chem-tests",
  [SearchFieldId.Eligibility]: "ec:eligibilities",
  [SearchFieldId.ProcessType]: "ec:process-types",
  [SearchFieldId.BusinessArea]: "public:business-areas",
  [SearchFieldId.ImportCertificateType]: "public:import-certificate-types",
  [SearchFieldId.Verifier]: "ec:verifiers",
  [SearchFieldId.HsCode]: "ec:hs-codes",
  [SearchFieldId.Package]: "public:package-types",
  [SearchFieldId.FreeSalePremise]: "ec:free-sale-premises",
  [SearchFieldId.WineSpecialProcess]: "ec:wine-special-processes",
  [SearchFieldId.WineGrapeVariety]: "ec:wine-grape-varieties",
  [SearchFieldId.WineColor]: "ec:wine-colours",
  [SearchFieldId.WineStyle]: "ec:wine-styles",
  [SearchFieldId.WineIngredient]: "ec:eu-wine-ingredients",
  [SearchFieldId.GeographicIndication]: "ec:wine-geographic-indications",
  [SearchFieldId.UnLocode]: "public:un-locode",
  [SearchFieldId.Document]: "ec:document-types",
  [SearchFieldId.CertificateDeliveryMethod]: "ec:certificate-delivery-methods",
  [SearchFieldId.WSMPS]: "ec:wsmps",
  [SearchFieldId.BottleSize]: "ec:bottle-sizes",
  [SearchFieldId.EstarLaboratory]: "ec:estar-laboratories",
  [SearchFieldId.EstarWineTest]: "ec:estar-wine-tests",
  [SearchFieldId.Product]: "private:products",
  [SearchFieldId.ExportCertificate]: "private:export-certificates",
  [SearchFieldId.OrganicPremise]: "ec:organic-premises",
  [SearchFieldId.Exporter]: "private:exporters",
  [SearchFieldId.DeAlcoholised]: "ec:de-alcoholised",
}

export enum SearchFieldWsmpSubType {
  BottlerAndWinemaker = "Bottler & Winemaker",
  Winemaker = "Winemaker",
  Bottler = "Bottler",
}
